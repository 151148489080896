import { createSlice } from "@reduxjs/toolkit";
import TurkishData from '../../Data/TURKISH.json';
import EnglishData from '../../Data/ENGLISH.json';
import { loadState, saveState } from '../../JS/LocalStorage'


const userLanguageFromLocalStorage = loadState('UserLanguageCode');
if (!userLanguageFromLocalStorage) saveState('UserLanguageCode', 'tr');

const currentLang = userLanguageFromLocalStorage === "en" ? EnglishData : TurkishData;


const initialState = {
    userLanguageFromLocalStorage,
    currentLanguage: JSON.stringify(currentLang),
}

export const userLanguageSlice = createSlice({
    name: 'Language',
    initialState,
    reducers: {
        changeLanguage: (state) => {
            if (state.userLanguageFromLocalStorage === "en") {
                state.userLanguageFromLocalStorage = "tr"
                saveState("UserLanguageCode", "tr")
                state.currentLanguage = JSON.stringify(TurkishData);
            } else {
                state.userLanguageFromLocalStorage = "en"
                saveState("UserLanguageCode", "en")
                state.currentLanguage = JSON.stringify(EnglishData);
            }
        }
    }
})

export const { changeLanguage } = userLanguageSlice.actions;

export default userLanguageSlice.reducer