import React, { useState } from 'react'
import ResponsiveAppBar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Layout from '../Components/Layout'
import { Box, Grid, Typography } from '@mui/material'
import BreadCrumb from '../Components/BreadCrumb'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import KitchenOpportunitiesImage from '../../src/Assets/Images/f2.jpeg'
import CampaignImage from '../../src/Assets/Images/intema-nisan-kampanya-detayjpg_MSXAZUTNG.jpg'
import Logo2 from '../../src/Assets/LOGO/TVMobilyaBlack.png'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Opportunities() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))
    const navigate = useNavigate();
    const [cardRaisedCampaign, setCardRaisedCampaign] = useState(false);
    const [cardRaisedOpportunities, setCardRaisedOpportunities] = useState(false);

    const toggleRaisedCampaign = () => {
        setCardRaisedCampaign(!cardRaisedCampaign)
    }

    const toggleRaisedOpportunities = () => {
        setCardRaisedOpportunities(!cardRaisedOpportunities)
    }
    return (
        <>
            <ResponsiveAppBar />
            <BreadCrumb name={translate.Firsatlar} category={translate.Firsatlar} categoryLink='Firsatlar' />
            <Layout >
                <Grid pl={2} pr={2} mt={10} container spacing={2}>
                    <Grid item xl={6} md={6} xs={12}>
                        <Card
                            onClick={() => navigate('/Mutfak')}
                            onMouseOver={toggleRaisedCampaign}
                            onMouseOut={toggleRaisedCampaign}
                            raised={cardRaisedCampaign}
                            sx={{
                                cursor: 'pointer',
                                ':&hover': {
                                }
                            }}>
                            <CardMedia
                                sx={{ height: 340 }}
                                image={CampaignImage}
                                title="Kampanyalar"
                            />
                            <CardContent>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        fontSize: '18px',
                                        fontWeight: '500',
                                        fontFamily: "Poppins , 'sans-serif'",
                                        fontStyle: 'normal',
                                        lineHeight: '30px',
                                        textAlign: 'center'
                                    }}>
                                    {translate.Kampanyalar}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xl={6} md={6} xs={12}>
                        <Card
                            onClick={() => navigate('/Iletisim')}
                            onMouseOver={toggleRaisedOpportunities}
                            onMouseOut={toggleRaisedOpportunities}
                            raised={cardRaisedOpportunities}
                            sx={{
                                cursor: 'pointer',
                                ':&hover': {
                                }
                            }}>
                            <CardMedia
                                sx={{ height: 340 }}
                                image={KitchenOpportunitiesImage}
                                title="Kampanyalar"
                            />
                            <CardContent>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        fontSize: '18px',
                                        fontWeight: '500',
                                        fontFamily: "Poppins , 'sans-serif'",
                                        fontStyle: 'normal',
                                        lineHeight: '30px',
                                        textAlign: 'center'
                                    }}>
                                    {translate.MutfakFirsatlari}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid mt={10} item xl={12} md={12} xs={12}>
                        <Grid container spacing={6}>
                            <Grid item xl={4} md={4} xs={12}>
                                <Typography sx={{
                                    mt: 2,
                                    fontSize: '32.2px',
                                    fontWeight: '600',
                                    fontFamily: "Poppins , 'sans-serif'",
                                    fontStyle: 'normal',
                                    lineHeight: '130%',
                                    textAlign: 'center'
                                }}>
                                    {translate.MutfakMobilyalarindaCazipFirsatlar}
                                </Typography>

                                <Typography sx={{
                                    mt: 2,
                                    fontSize: '18px',
                                    color: 'rgb(130,130,130)',
                                    fontWeight: '400',
                                    fontFamily: "Poppins , 'sans-serif'",
                                    fontStyle: 'normal',
                                    lineHeight: '130%',
                                    textAlign: 'justify'
                                }}>
                                    {translate.MutfakMobilyaniziYenilemeZamaniGeldi}
                                </Typography>
                            </Grid>


                            <Grid item xl={4} md={4} xs={12}>
                                <Typography sx={{
                                    mt: 2,
                                    fontSize: '32.2px',
                                    fontWeight: '600',
                                    fontFamily: "Poppins , 'sans-serif'",
                                    fontStyle: 'normal',
                                    lineHeight: '130%',
                                    textAlign: 'center'
                                }}>
                                    {translate.IndirimKampanyalariVeAvantajliIsbirlikleri}
                                </Typography>

                                <Typography sx={{
                                    mt: 2,
                                    fontSize: '18px',
                                    color: 'rgb(130,130,130)',
                                    fontWeight: '400',
                                    fontFamily: "Poppins , 'sans-serif'",
                                    fontStyle: 'normal',
                                    lineHeight: '130%',
                                    textAlign: 'justify'
                                }}>
                                    {translate.EvinizinEnOzelAlaniOlanMutfaginizi}
                                </Typography>
                            </Grid>



                            <Grid item xl={4} md={4} xs={12}>
                                <Typography sx={{
                                    mt: 2,
                                    fontSize: '32.2px',
                                    fontWeight: '600',
                                    fontFamily: "Poppins , 'sans-serif'",
                                    fontStyle: 'normal',
                                    lineHeight: '130%',
                                    textAlign: 'center'
                                }}>
                                    {translate.HemenBizeUlasin}
                                </Typography>

                                <Typography sx={{
                                    mt: { xs: 2, md: 7},
                                    fontSize: '18px',
                                    color: 'rgb(130,130,130)',
                                    fontWeight: '400',
                                    fontFamily: "Poppins , 'sans-serif'",
                                    fontStyle: 'normal',
                                    lineHeight: '130%',
                                    textAlign: 'justify'
                                }}>
                                    {translate.MutfakMobilyaniziYenilemeKonusundaSizeDestekOlmakIcinBuradayiz}
                                    <a style={{ textDecoration: 'none', color: 'rgb(130,130,130)', }} href='tel:905416934209'>
                                        <Typography sx={{
                                            ml: 1,
                                            mr: 1,
                                            fontSize: '18px',
                                            color: 'rgb(130,130,130)',
                                            fontWeight: '600',
                                            fontFamily: "Poppins , 'sans-serif'",
                                            fontStyle: 'normal',
                                            lineHeight: '130%',
                                            display: 'inline-flex'
                                        }}>
                                            +(90) 541 69 342 09
                                        </Typography>
                                    </a>
                                    {translate.MutfakMobilyaniziYenilemeKonusundaSizeDestekOlmakIcinBuradayiz2}
                                </Typography>

                                <Typography sx={{
                                    mt: 1,
                                    fontSize: '18px',
                                    color: 'rgb(130,130,130)',
                                    fontWeight: '400',
                                    fontFamily: "Poppins , 'sans-serif'",
                                    fontStyle: 'normal',
                                    lineHeight: '130%',
                                    textAlign: 'justify'
                                }}>
                                    {translate.MutfaklarinizdaTarzVeFonksiyonelligiBirArayaGetiren}
                                </Typography>
                            </Grid>


                        </Grid>







                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <img
                                style={{
                                    height: '154px'
                                }}
                                src={Logo2} alt='TanrıverdiMobilya ' />
                        </Box>
                    </Grid>
                </Grid>
            </Layout>
            <Footer />
        </>
    )
}

export default Opportunities