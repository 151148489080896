import React, { useState } from 'react';
import Login from './Login';
import blogData from '../Data/db.json'; // JSON dosyasını import et

const BlogForm = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handleLogin = (isLoggedIn) => {
      setIsAuthenticated(isLoggedIn);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const blogPost = {
          title,
          content,
          image: image ? URL.createObjectURL(image) : null
        };

        // Mevcut blogları al
        const existingBlogs = blogData.blogs || [];
        // Yeni blog yazısını ekle
        existingBlogs.push(blogPost);
        // Güncellenmiş blog listesini JSON dosyasına tekrar yaz
        const updatedData = { ...blogData, blogs: existingBlogs };
        // JSON dosyasını güncelle
        // Not: Bu adım, frontend'de çalışmaz, yalnızca geliştirme sırasında test amaçlı kullanılabilir
        // Gerçek bir backend kullanmadan JSON dosyasını güncellemek mümkün değildir
        // Bu yüzden bu adım, sadece geliştirme sırasında geçici bir çözümdür
        // Gerçek bir uygulamada, bir backend API kullanılmalıdır
        fetch('/Data/db.json', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updatedData)
        }).then(() => {
          // Formu temizle
          setTitle('');
          setContent('');
          setImage(null);
        }).catch(error => console.error('Error saving blog post:', error));
      };

    return (
        <div>
            {isAuthenticated ? (
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Title: </label>
                        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div>
                        <label>Content: </label>
                        <textarea value={content} onChange={(e) => setContent(e.target.value)} />
                    </div>
                    <div>
                        <label>Image: </label>
                        <input type="file" onChange={(e) => setImage(e.target.files[0])} />
                    </div>
                    <button type="submit">Save Blog</button>
                </form>
            ) : (
                <Login onLogin={handleLogin} />
            )}

        </div>
    );
};

export default BlogForm;
