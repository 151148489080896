import React from 'react'
import ResponsiveAppBar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Grid, Typography } from '@mui/material'
import AnahtarTeslimImage from '../Assets/Images/anahtar-teslim-proje-nedir-770x460-removebg-preview.png'
import BoyaHizmetiImage from '../Assets/Images/boya-badana-fiyatlari-ne-kadar-2020-10-21-050658-45-removebg-preview.png'
import FayansHizmetiImage from '../Assets/Images/athena-cielo-bathroom-install.jpg'
import icMimarlikHizmetiImage from '../Assets/Images/ic-mimari-nedir-ve-ic-mimarligin-incelikleri-nelerdir-1.jpg'
import Layout from '../Components/Layout'
import { useSelector } from 'react-redux'
function OurServices() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))
    return (
        <>
            <ResponsiveAppBar />
            <Layout>
                <Grid sx={{ display: { md: 'flex', xs: 'none' } }} mt={10} container spacing={0}>
                    {/* // Anahtar Teslim */}
                    <Grid sx={{ pl: { xs: 1, md: 10 }, flexDirection: { xs: 'column-reverse' } }} item xl={6} md={6} xs={12}>
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontWeight: '500',
                                letterSpacing: '0.0093em',
                                fontFamily: "Poppins , 'sans-serif'",
                                lineHeight: '36px',
                                fontStyle: 'normal',

                            }}>
                            {translate.AnahtarTeslim}
                        </Typography>

                        <Typography
                            sx={{
                                mt: 2,
                                fontSize: '54px',
                                width: { xs: 'auto', md: '580px' },
                                fontWeight: '700',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '130%'

                            }}>
                            {translate.VereceginizHizmetleriTekSeferdeHallediyoruz}
                        </Typography>

                        <Typography
                            sx={{
                                color: 'rgb(136, 154, 164)',
                                mt: 2,
                                fontSize: '18px',
                                width: { xs: 'auto', md: '580px' },
                                fontWeight: '500',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '170%'

                            }}>
                            {translate.BizdenAlacaginizBirdenFazlaHizmettiSizinIcinTekSeferdeHallediyoruz}
                        </Typography>
                    </Grid>

                    <Grid align='right' item xl={6} md={6} xs={12}>
                        <img style={{
                            height: 'auto',
                            width: '100%',
                            objectFit: 'cover'
                        }} src={AnahtarTeslimImage} alt='Anahtar Teslim' />
                    </Grid>

                    {/* İç Mimarlık Hizmeti */}

                    <Grid mt={10} sx={{ pl: { xs: 1, md: 10 } }} item xl={6} md={6} xs={12}>
                        <img
                            style={{
                                height: 'auto',
                                width: '100%',
                                objectFit: 'cover'
                            }}
                            src={icMimarlikHizmetiImage} alt='Anahtar Teslim' />
                    </Grid>

                    <Grid mt={10} sx={{ pr: { xs: 1, md: 10 } }} align='right' item xl={6} md={6} xs={12}>
                        <Typography
                            sx={{
                                width: { xs: 'auto', md: '580px' },
                                fontSize: '24px',
                                fontWeight: '500',
                                letterSpacing: '0.0093em',
                                fontFamily: "Poppins , 'sans-serif'",
                                lineHeight: '36px',
                                fontStyle: 'normal',

                            }}>
                            {translate.IcMimarlikHizmeti}
                        </Typography>

                        <Typography
                            sx={{
                                textAlign: 'right',
                                mt: 2,
                                fontSize: '54px',
                                width: { xs: 'auto', md: '580px' },
                                fontWeight: '700',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '130%'

                            }}>
                            {translate.CarpiciDekorasyonKonseptTasarimRenkUyumu}
                        </Typography>

                        <Typography
                            sx={{
                                color: 'rgb(136, 154, 164)',
                                mt: 2,
                                fontSize: '18px',
                                width: { xs: 'auto', md: '580px' },
                                fontWeight: '500',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '170%'

                            }}>
                            {translate.YasamAlaniniziStiliniziYansitmalidir}
                        </Typography>
                    </Grid>

                    {/* // Boya Hizmeti */}

                    <Grid mt={10} sx={{ pl: { xs: 1, md: 10 } }} item xl={6} md={6} xs={12}>
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontWeight: '500',
                                letterSpacing: '0.0093em',
                                fontFamily: "Poppins , 'sans-serif'",
                                lineHeight: '36px',
                                fontStyle: 'normal',

                            }}>
                            {translate.BoyaHizmeti}
                        </Typography>

                        <Typography
                            sx={{
                                mt: 2,
                                fontSize: '54px',
                                fontWeight: '700',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '130%'

                            }}>
                            {translate.BizYapamazsakKimseYapamaz}
                        </Typography>

                        <Typography
                            sx={{
                                color: 'rgb(136, 154, 164)',
                                mt: 2,
                                fontSize: '18px',
                                fontWeight: '500',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '170%'

                            }}>
                            {translate.KonutlarOfislerOtellerKafeteryalarSiteOrtakAlanlar}
                        </Typography>
                    </Grid>

                    <Grid mt={10} align='right' item xl={6} md={6} xs={12}>
                        <img style={{
                            height: 'auto',
                            width: '100%',
                            objectFit: 'cover'
                        }} src={BoyaHizmetiImage} alt='Anahtar Teslim' />
                    </Grid>


                    {/* Fayans Hizmeti */}

                    <Grid mt={10} sx={{ pl: { xs: 1, md: 5 } }} align='left' item xl={6} md={6} xs={12}>
                        <img style={{
                            height: 'auto',
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: '10px'
                        }} src={FayansHizmetiImage} alt='Anahtar Teslim' />
                    </Grid>

                    <Grid mt={10} align='right' sx={{ pr: { xs: 1, md: 10 } }} item xl={6} md={6} xs={12}>
                        <Typography
                            sx={{
                                width: { xs: 'auto', md: '580px' },
                                fontSize: '24px',
                                fontWeight: '500',
                                letterSpacing: '0.0093em',
                                fontFamily: "Poppins , 'sans-serif'",
                                lineHeight: '36px',
                                fontStyle: 'normal',

                            }}>
                            {translate.Fayans}
                        </Typography>

                        <Typography
                            sx={{
                                mt: 2,
                                fontSize: '54px',
                                width: { xs: 'auto', md: '580px' },
                                fontWeight: '700',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '130%'

                            }}>
                            {translate.AlanindaProfesyonelUstalarIleFayansHizmeti}
                        </Typography>

                        <Typography
                            sx={{
                                color: 'rgb(136, 154, 164)',
                                mt: 2,
                                fontSize: '18px',
                                width: { xs: 'auto', md: '580px' },
                                fontWeight: '500',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '170%'

                            }}>
                            {translate.FayanslariniziKonusundaUzmanUstalarIleDosuyoruz}
                        </Typography>
                    </Grid>



                </Grid>


                <Grid sx={{ display: { md: 'none', xs: 'flex' } }} mt={10} container spacing={0}>
                    {/* // Anahtar Teslim */}
                    <Grid sx={{ pl: 1, pr: 1 }} item xl={6} md={6} xs={12}>
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontWeight: '500',
                                letterSpacing: '0.0093em',
                                fontFamily: "Poppins , 'sans-serif'",
                                lineHeight: '36px',
                                fontStyle: 'normal',
                                textAlign: 'center'

                            }}>
                            {translate.AnahtarTeslim}
                        </Typography>

                        <Typography
                            sx={{
                                mt: 2,
                                fontSize: '32px',
                                width: { xs: 'auto', md: '580px' },
                                fontWeight: '700',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '130%',
                                textAlign: 'center'

                            }}>
                            {translate.VereceginizHizmetleriTekSeferdeHallediyoruz}
                        </Typography>

                        <Typography
                            sx={{
                                color: 'rgb(136, 154, 164)',
                                mt: 2,
                                mb: 2,
                                fontSize: '18px',
                                width: { xs: 'auto', md: '580px' },
                                fontWeight: '500',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '170%',
                                textAlign: 'center'

                            }}>
                            {translate.BizdenAlacaginizBirdenFazlaHizmettiSizinIcinTekSeferdeHallediyoruz}
                        </Typography>
                    </Grid>

                    <Grid align='right' item xl={6} md={6} xs={12}>
                        <img style={{
                            height: 'auto',
                            width: '100%',
                            objectFit: 'cover'
                        }} src={AnahtarTeslimImage} alt='Anahtar Teslim' />
                    </Grid>

                    {/* İç Mimarlık Hizmeti */}

                    <Grid mt={10} sx={{ pr: 3, pl: 3 }} align='right' item xl={6} md={6} xs={12}>
                        <Typography
                            sx={{
                                width: { xs: 'auto', md: '580px' },
                                fontSize: '24px',
                                fontWeight: '500',
                                letterSpacing: '0.0093em',
                                fontFamily: "Poppins , 'sans-serif'",
                                lineHeight: '36px',
                                fontStyle: 'normal',
                                textAlign: 'center'

                            }}>
                            {translate.IcMimarlikHizmeti}
                        </Typography>

                        <Typography
                            sx={{
                                mt: 2,
                                fontSize: '34px',
                                width: { xs: 'auto', md: '580px' },
                                fontWeight: '700',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '130%',
                                textAlign: 'center'

                            }}>
                            {translate.CarpiciDekorasyonKonseptTasarimRenkUyumu}
                        </Typography>

                        <Typography
                            sx={{
                                textAlign: 'justify',
                                color: 'rgb(136, 154, 164)',
                                mt: 2,
                                fontSize: '18px',
                                width: { xs: 'auto', md: '580px' },
                                fontWeight: '500',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '170%'

                            }}>
                            {translate.YasamAlaniniziStiliniziYansitmalidir}
                        </Typography>
                    </Grid>


                    <Grid mt={10} sx={{ pl: { xs: 1, md: 10 } }} item xl={6} md={6} xs={12}>
                        <img
                            style={{
                                height: 'auto',
                                width: '100%',
                                objectFit: 'cover'
                            }}
                            src={icMimarlikHizmetiImage} alt='Anahtar Teslim' />
                    </Grid>

                    {/* // Boya Hizmeti */}

                    <Grid mt={10} sx={{ pl: 1, pr: 1 }} item xl={6} md={6} xs={12}>
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontWeight: '500',
                                letterSpacing: '0.0093em',
                                fontFamily: "Poppins , 'sans-serif'",
                                lineHeight: '36px',
                                fontStyle: 'normal',
                                textAlign: 'center'

                            }}>
                            {translate.BoyaHizmeti}
                        </Typography>

                        <Typography
                            sx={{
                                mt: 2,
                                fontSize: '44px',
                                fontWeight: '700',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '130%',
                                textAlign: 'center'

                            }}>
                            {translate.BizYapamazsakKimseYapamaz}
                        </Typography>

                        <Typography
                            sx={{
                                color: 'rgb(136, 154, 164)',
                                mt: 2,
                                fontSize: '18px',
                                fontWeight: '500',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '170%',
                                textAlign: 'center'

                            }}>
                            {translate.KonutlarOfislerOtellerKafeteryalarSiteOrtakAlanlar}
                        </Typography>
                    </Grid>

                    <Grid mt={10} align='right' item xl={6} md={6} xs={12}>
                        <img style={{
                            height: 'auto',
                            width: '100%',
                            objectFit: 'cover'
                        }} src={BoyaHizmetiImage} alt='Anahtar Teslim' />
                    </Grid>


                    {/* Fayans Hizmeti */}

                    <Grid mt={10} align='right' sx={{ pr: 1, pl: 1 }} item xl={6} md={6} xs={12}>
                        <Typography
                            sx={{
                                width: { xs: 'auto', md: '580px' },
                                fontSize: '24px',
                                fontWeight: '500',
                                letterSpacing: '0.0093em',
                                fontFamily: "Poppins , 'sans-serif'",
                                lineHeight: '36px',
                                fontStyle: 'normal',
                                textAlign: 'center'

                            }}>
                            {translate.Fayans}
                        </Typography>

                        <Typography
                            sx={{
                                mt: 2,
                                fontSize: '34px',
                                width: { xs: 'auto', md: '580px' },
                                fontWeight: '700',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '130%',
                                textAlign: 'center'

                            }}>
                            {translate.AlanindaProfesyonelUstalarIleFayansHizmeti}
                        </Typography>

                        <Typography
                            sx={{
                                color: 'rgb(136, 154, 164)',
                                mt: 2,
                                fontSize: '18px',
                                width: { xs: 'auto', md: '580px' },
                                fontWeight: '500',
                                letterSpacing: '0.00938em',
                                fontFamily: "Poppins , 'sans-serif'",
                                fontStyle: 'normal',
                                lineHeight: '170%',
                                textAlign: 'center'

                            }}>
                            {translate.FayanslariniziKonusundaUzmanUstalarIleDosuyoruz}
                        </Typography>
                    </Grid>
                    <Grid mt={10} sx={{ pl: 1, pr: 1 }} align='left' item xl={6} md={6} xs={12}>
                        <img style={{
                            height: 'auto',
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: '10px'
                        }} src={FayansHizmetiImage} alt='Anahtar Teslim' />
                    </Grid>


                </Grid>
            </Layout>

            <Footer />
        </>
    )
}

export default OurServices