import React from 'react'
import ResponsiveAppBar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Box, Grid } from '@mui/material'
import BreadCrumb from '../Components/BreadCrumb'
import Layout from '../Components/Layout'
import KitchenImage1 from '../Assets/Images/header-corona-main-features-page.jpg'
import ItemDescCard from '../Components/ItemDescCard'

import AkrilikImage1 from '../Assets/Images/Akrilik/12.jpeg'
import MatAkrilikImage from '../Assets/Images/Mat Akrilik/10.jpeg'
import LakeImage1 from '../Assets/Images/Lake/bannerlake.jpeg'
import BalonImage1 from '../Assets/Images/Balon/3.jpeg'
import { useSelector } from 'react-redux'
function Kitchen() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))
    return (
        <>
            <ResponsiveAppBar />
            <BreadCrumb categoryLink='Mutfak' nameLink='Mutfak' name={translate.MutfakGrubu} category={translate.Mutfak} />
            <Layout>
                <Grid pb={10} sx={{ background: '#f7f7f7' }} container spacing={0} mt={10}>
                    <Grid item xl={12} md={12} xs={12}>
                        <Box>
                            <img
                                style={{
                                    maxHeight: 'auto',
                                    objectFit: 'cover',
                                    width: '100%'
                                }}
                                src={KitchenImage1} alt='Tanriverdi Kitchen 1' />
                        </Box>
                    </Grid>

                    <Grid pl={1} pr={1} item xl={12} md={12} xs={12}>
                        <Grid mt={10} sx={{ display: 'flex', justifyContent: 'space-evenly' }} container spacing={0}>
                            <Grid sx={{ display: 'contents' }} item xl={4} md={4} xs={12}>
                                <ItemDescCard
                                    name="Dawson"
                                    image={AkrilikImage1}
                                    description={translate.DawsonUrunTanitim}
                                />
                            </Grid>
                            <Grid sx={{ display: 'contents' }} item xl={4} md={4} xs={12}>
                                <ItemDescCard
                                    name="Trend"
                                    image={BalonImage1}
                                    description={translate.TrendUrunTanitim}
                                />
                            </Grid>
                            <Grid sx={{ display: 'contents' }} item xl={4} md={4} xs={12}>
                                <ItemDescCard
                                    name="Madison"
                                    image={LakeImage1}
                                    description={translate.MadisonUrunTanitim}
                                />
                            </Grid>
                            <Grid sx={{ display: 'contents' }} item xl={4} md={4} xs={12}>
                                <ItemDescCard
                                    name="Aldana"
                                    image={MatAkrilikImage}
                                    description={translate.AldanaUrunTanitim}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Layout>
            <Footer />
        </>
    )
}

export default Kitchen