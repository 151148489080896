import React from 'react'
import ResponsiveAppBar from '../Components/Navbar'
import BreadCrumb from '../Components/BreadCrumb'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../Components/Layout';
import { Box, Grid, Typography } from '@mui/material';
import Footer from '../Components/Footer';

function Projects() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))
    const link = useLocation();
    console.log(link.pathname.split('/')[2]);
    const navigate = useNavigate();
    const handleItemButtonClick = () => {
        navigate('/Iletisim');
    }

    return (
        <>
            <ResponsiveAppBar />
            <BreadCrumb name='Projelerimiz' category='Projelerimiz' categoryLink='Projelerimiz' />
            <Layout>
                <Grid container spacing={1}>
                    <Grid item xl={12} md={12} xs={12}>
                        <Typography sx={{
                            mt: 2,
                            fontSize: '32.2px',
                            fontWeight: '600',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '130%',
                            textAlign: 'center'
                        }}>
                            Yaratıcılığınızı Keşfedin: İlham Veren Mobilya Projeleri
                        </Typography>

                        <Typography sx={{
                            m: '1em 10% 1em 10%',
                            fontSize: '18px',
                            color: 'rgb(130,130,130)',
                            fontWeight: '400',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '130%',
                            textAlign: 'justify'
                        }}>
                            Her zevke ve tarza uygun çeşitlilikte mobilya projelerini keşfedin. İster modern bir dokunuş arayın, ister klasik bir tarzı tercih edin, size uygun birçok seçenek bulacaksınız. Projelerimiz, evinizdeki yaşam alanlarını dönüştürmek için size rehberlik edecek pratik ipuçları sunar. Hayalinizdeki ev dekorasyonunu gerçeğe dönüştürmek için ilham alın ve tasarımınızı başlatın.
                        </Typography>
                    </Grid>

                    <Grid mt={10} item xl={12} md={12} xs={12}>
                        <Typography sx={{
                            mt: 2,
                            ml: 5,
                            fontSize: '28px',
                            fontWeight: '600',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '130%',
                            textAlign: 'left'
                        }}>
                           Hukuk Bürosu Projemiz
                        </Typography>
                    </Grid>
                    <Grid item xl={8} md={6} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xl={12} md={12} xs={12}>
                                <Typography sx={{
                                    mb: '1.5em',
                                    ml: '1em',
                                    mr: '1em',
                                    fontSize: '16px',
                                    color: 'rgb(130,130,130)',
                                    fontWeight: '400',
                                    fontFamily: "Poppins , 'sans-serif'",
                                    fontStyle: 'normal',
                                    lineHeight: '130%',
                                    textAlign: 'justify'
                                }}>
                                    Hukuk bürosu projemizde,işlevselliği ve estetiği bir araya getirerek modern bir ofis ortamı oluşturduk. Ahşap ve metal detayların uyumlu kullanımıyla, profesyonel ve şık bir atmosfer yarattık.
                                </Typography>

                                <Typography sx={{
                                    mt: '1.5em',
                                    mb: '3.2em',
                                    ml: '1em',
                                    mr: '1em',
                                    fontSize: '16px',
                                    color: 'rgb(130,130,130)',
                                    fontWeight: '400',
                                    fontFamily: "Poppins , 'sans-serif'",
                                    fontStyle: 'normal',
                                    lineHeight: '130%',
                                    textAlign: 'justify'
                                }}>
                                    Ofis mobilyalarında ergonomi ve kullanım kolaylığını ön planda tutarak, çalışanların verimliliğini artırdık. Müşteri bekleme alanlarından toplantı odalarına kadar her detayı özenle tasarlayarak, konfor ve prestij sunuyoruz. Hukuk büromuzun iç mekan tasarımı, şeffaflık ve güven duygusunu yansıtarak müşterilerimize güven veren bir ortam sunmayı amaçlıyor.
                                </Typography>
                            </Grid>
                            <Grid item xl={12} md={12} xs={12}>
                                <Box style={{ height: '100%', width: '100%' }}>
                                    <img style={{ height: 'auto', width: '100%' }} src={require('../Assets/Images/Hukuk2/hukuk7.webp')} alt='Image5' />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid mb={10} item xl={4} md={6} xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xl={12} md={12} xs={12}>
                                <Box>
                                    <Box style={{ height: '100%', width: '100%' }}>
                                        <img style={{ height: 'auto', width: '100%' }} src={require('../Assets/Images/Hukuk1/hukuk2.webp')} alt='Image5' />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={6} md={6} xs={12}>
                                <Box>
                                    <Box style={{ height: '100%', width: '100%' }}>
                                        <img style={{ height: 'auto', width: '100%' }} src={require('../Assets/Images/Hukuk1/hukuk3.webp')} alt='Image5' />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={6} md={6} xs={12}>
                                <Box>
                                    <Box style={{ height: '100%', width: '100%' }}>
                                        <img style={{ height: 'auto', width: '100%' }} src={require('../Assets/Images/Hukuk1/hukuk6.webp')} alt='Image5' />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                    </Grid>


                    <Grid item xl={12} md={12} xs={12}>
                        <Typography sx={{
                            mt: 2,
                            ml: 5,
                            fontSize: '28px',
                            fontWeight: '600',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '130%',
                            textAlign: 'left'
                        }}>
                             Diyet Enstitüsü Projemiz
                        </Typography>
                    </Grid>
                    <Grid item xl={8} md={6} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xl={12} md={12} xs={12}>
                                <Typography sx={{
                                    mb: '1.5em',
                                    ml: '1em',
                                    mr: '1em',
                                    fontSize: '16px',
                                    color: 'rgb(130,130,130)',
                                    fontWeight: '400',
                                    fontFamily: "Poppins , 'sans-serif'",
                                    fontStyle: 'normal',
                                    lineHeight: '130%',
                                    textAlign: 'justify'
                                }}>
                                    Hukuk bürosu projemizde,işlevselliği ve estetiği bir araya getirerek modern bir ofis ortamı oluşturduk. Ahşap ve metal detayların uyumlu kullanımıyla, profesyonel ve şık bir atmosfer yarattık.
                                </Typography>

                                <Typography sx={{
                                    mt: '1.5em',
                                    mb: '3.2em',
                                    ml: '1em',
                                    mr: '1em',
                                    fontSize: '16px',
                                    color: 'rgb(130,130,130)',
                                    fontWeight: '400',
                                    fontFamily: "Poppins , 'sans-serif'",
                                    fontStyle: 'normal',
                                    lineHeight: '130%',
                                    textAlign: 'justify'
                                }}>
                                    Ofis mobilyalarında ergonomi ve kullanım kolaylığını ön planda tutarak, çalışanların verimliliğini artırdık. Müşteri bekleme alanlarından toplantı odalarına kadar her detayı özenle tasarlayarak, konfor ve prestij sunuyoruz. Hukuk büromuzun iç mekan tasarımı, şeffaflık ve güven duygusunu yansıtarak müşterilerimize güven veren bir ortam sunmayı amaçlıyor.
                                </Typography>
                            </Grid>
                            <Grid item xl={12} md={12} xs={12}>
                                <Box style={{ height: '100%', width: '100%' }}>
                                    <img style={{ height: 'auto', width: '100%' }} src={require('../Assets/Images/Diyet1/diyet2.webp')} alt='Image5' />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xl={4} md={6} xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xl={12} md={12} xs={12}>
                                <Box>
                                    <Box style={{ height: '100%', width: '100%' }}>
                                        <img style={{ height: 'auto', width: '100%' }} src={require('../Assets/Images/Diyet1/diyet1.webp')} alt='Image5' />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={6} md={6} xs={12}>
                                <Box>
                                    <Box style={{ height: '100%', width: '100%' }}>
                                        <img style={{ height: 'auto', width: '100%' }} src={require('../Assets/Images/Diyet1/diyet3.webp')} alt='Image5' />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={6} md={6} xs={12}>
                                <Box>
                                    <Box style={{ height: '100%', width: '100%' }}>
                                        <img style={{ height: 'auto', width: '100%' }} src={require('../Assets/Images/Diyet1/diyet6.webp')} alt='Image5' />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Layout>
            <Footer />
        </>
    )
}

export default Projects