import { Button, Grid, TextField, Typography, Link } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, useEffect } from 'react'
import Footer from '../Components/Footer'
import ResponsiveAppBar from '../Components/Navbar'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useSelector } from 'react-redux'


function ConnectUs() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const SendMailFunc = () => {
        if (name === '' || lastname === '' || message === '' || phoneNumber === '') {
            alert('test2');
        } else {
            alert('test');
        }
    }

    return (
        <>
            <ResponsiveAppBar />
            <Grid mt={10} minHeight='70vh' mb={10} container spacing={0}>
                <Grid
                    display='flex'
                    alignContent='center'
                    justifyContent='center'
                    alignItems='center'
                    item xl={6} md={6} xs={12}>
                    <Box sx={{ width: { md: '50%', xs: '80%' }, height: '100%' }}>
                        <Box mb={1} pb={5}>
                            <Typography
                                sx={{
                                    fontSize: '25px'
                                }}
                            >
                                Tanrıverdi Mutfak
                            </Typography>

                            <Link
                                mt={2}
                                underline='none'
                                sx={{
                                    display: 'inlie-flex',
                                    color: 'black'
                                }}
                                href='tel:905416934209'>
                                <LocalPhoneIcon sx={{ display: 'inlie-flex', fontSize: '1.7em', margin: '0 5px', }} />
                                <Typography
                                    sx={{
                                        fontFamily: "'Montserrat', sans-serif",
                                        fontWeight: 700,
                                        textDecoration: 'none',
                                        fontSize: '22px',
                                        background: "black",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                    }} s
                                    textAlign="center">  +(90) 541 69 342 09</Typography>
                            </Link>

                            <Link
                                underline='none'
                                mt={2}
                                sx={{
                                    display: 'inlie-flex',
                                    color: 'black'
                                }}
                                href='https://wa.me/905416934209'>
                                <WhatsAppIcon sx={{ display: 'inlie-flex', fontSize: '1.7em', margin: '0 5px', }} />
                                <Typography
                                    sx={{
                                        fontFamily: "'Montserrat', sans-serif",
                                        fontWeight: 700,
                                        textDecoration: 'none',
                                        fontSize: '22px',
                                        background: "black",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                    }}
                                    textAlign="center">  +(90) 541 69 342 09</Typography>
                            </Link>
                        </Box>

                        <Typography
                            sx={{
                                fontFamily: "'Montserrat', sans-serif",
                                fontWeight: 700,
                                textDecoration: 'none',
                                fontSize: '22px',
                                background: "black",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                width: '1170px',
                            }}
                        >
                            {translate.BizimleIletisimKurun}
                        </Typography>

                        <Box mt={3}>
                            <TextField
                                onChange={(e) => { setName(e.target.value) }}
                                label={translate.Adiniz}
                                fullWidth
                            />
                        </Box>
                        <Box mt={1}>
                            <TextField
                                onChange={(e) => { setLastname(e.target.value) }}
                                label={translate.SoyAdiniz}
                                fullWidth
                            />
                        </Box>
                        <Box mt={1}>
                            <TextField
                                onChange={(e) => { setPhoneNumber(e.target.value) }}
                                label={translate.TelefonNumaraniz}
                                fullWidth
                            />
                        </Box>
                        <Box mt={1}>
                            <TextField
                                onChange={(e) => { setMessage(e.target.value) }}
                                label={translate.Mesajinin}
                                rows={4}
                                multiline
                                fullWidth
                            />
                        </Box>
                        <Box align='center' mt={1}>
                            <Button
                                mt={2}
                                onClick={SendMailFunc}
                                variant="contained">
                                {translate.BizeMailGonderin}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid sx={{ mt: { xs: 4, md: 0 }, minHeight: { xs: '70vh', md: 'auto' } }} item xl={6} md={6} xs={12}>
                    <iframe
                        title='Google Maps Location'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3058.2919147173143!2d32.90402777566381!3d39.95722568347876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3520c47f66551%3A0xc36c29967df0b78f!2zw5ZuZGVyLCBCaWxlY2lrIFNrLiwgMDYxNjUgQWx0xLFuZGHEny9BbmthcmE!5e0!3m2!1str!2str!4v1684593660411!5m2!1str!2str"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </Grid>
            </Grid >
            <Footer />
        </>
    )
}

export default ConnectUs