import React from 'react';
import ResponsiveAppBar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { Box, Grid, Typography, Card, CardContent, CardMedia } from '@mui/material';
import BreadCrumb from '../Components/BreadCrumb';
import Layout from '../Components/Layout';
import blogData from '../Data/db.json'; // JSON dosyasını import et

import { useSelector } from 'react-redux';
import { loadState } from '../JS/LocalStorage';

function Blog() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));

    const userLanguageFromLocalStorage = loadState('UserLanguageCode');
    const currentLang = userLanguageFromLocalStorage === "en" ? 'en' : 'tr';


    // blogData içinden blogları al
    const blogs = blogData.blogs;

    return (
        <>
            <ResponsiveAppBar />
            <BreadCrumb categoryLink='Blog' nameLink='Blog' name={translate.Blog} category={translate.Blog} />
            <Layout>
                <Grid pb={10} mt={1} sx={{ background: '#f7f7f7' }} container spacing={3}>
                    <Grid sx={{ background: 'white' }} item xl={12} md={12} xs={12}>
                        <Typography sx={{
                            fontSize: '32.2px',
                            fontWeight: '600',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '130%',
                            textAlign: 'center'
                        }}>
                            Tanrıverdi Mutfak Blog
                        </Typography>

                        <Typography sx={{
                            m: '1em 10% 1em 10%',
                            fontSize: '18px',
                            color: 'rgb(130,130,130)',
                            fontWeight: '400',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '130%',
                            textAlign: 'center'
                        }}>
                           {translate.TanriverdiMutfakolarakMutfaktasarimlari}
                        </Typography>
                    </Grid>
                    {blogs.map((blog, index) => (
                        <Grid key={index} item xs={12}>
                            <Box display="flex" alignItems="center" p={2} >
                                <Card sx={{ display: 'flex' }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 800, objectFit: 'cover' }}
                                        image={blog.image}
                                        alt={blog.title[currentLang]}
                                    />
                                    <CardContent>
                                        <Typography sx={{
                                           mt: 2,
                                           mb:2,
                                           fontSize: '19px',
                                           fontWeight: '600',
                                           fontFamily: "Poppins , 'sans-serif'",
                                           fontStyle: 'normal',
                                           lineHeight: '130%',
                                           textAlign: 'center'
                                        }}>
                                            {blog.title[currentLang]}
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: '16px',
                                            color: 'rgb(130,130,130)',
                                            fontWeight: '400',
                                            fontFamily: "Poppins , 'sans-serif'",
                                            fontStyle: 'normal',
                                            lineHeight: '160%',
                                            textAlign: 'justify'
                                        }}>
                                            {blog.content[currentLang]}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Layout>
            <Footer />
        </>
    );
}

export default Blog;
