import './App.css';
import ResponsiveAppBar from './Components/Navbar';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Footer from './Components/Footer';
import HeroAreaImageSlider from './Components/HeroAreaImageSlider';
import ItemSlider from './Components/ItemSlider';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import ProjectProcess from './Components/ProjectProcess';
function App() {
  const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))
  return (
    <>
      <ResponsiveAppBar isHomePage={true} />
      <Grid
        container spacing={0}>
        <Grid
          mt={10}
          align='center'
          sx={{ width: '1920px' }}
          item xl={12} md={12} xs={12}>
          <HeroAreaImageSlider />
        </Grid>

        <Grid mt={10} mb={5} item xl={12} md={12} xs={12}>
          <Grid container spacing={0}>
            <Grid align='center' item xl={6} md={6} xs={12}>
              <Box>
                <img
                  style={{
                    height: '90%',
                    width: '90%',
                    filter: 'grayscale(1)',
                    borderRadius: '40px',
                  }}
                  src={require(`./Assets/Hakkimizda/1.jpeg`)} alt='Hakkimizda1' />
              </Box>
            </Grid>
            <Grid
              pl={3}
              pr={3}
              item xl={6} md={6} xs={12}>
              <Typography
                sx={{
                  marginTop: { xs: 3, md: 0 },
                  fontSize: '2.2rem',
                  lineHeight: 1,
                  fontWeight: 400,
                  color: '#2d2f32',
                  letterSpacing: '0.1em',
                  textAlign: { xs: 'center', md: 'left' }
                }}
              >
                {translate.DenItibaren}
              </Typography>
              <Typography
                sx={{
                  mt: 4,
                  fontWeight: '400',
                  fontFamily: "Poppins , 'sans-serif'",
                  fontSize: '1.2rem',
                  lineHeight: 2
                }}
              >
                {translate.YilindaKurulmusOlan}
              </Typography>
              <Typography
                sx={{
                  mt: 3,
                  fontWeight: '400',
                  fontFamily: "Poppins , 'sans-serif'",
                  fontSize: '1.2rem',
                  lineHeight: 2
                }}>
                {translate.FabrikamizSonTeknoloji}
              </Typography>
              <Grid align='center' mt={6} container spacing={0}>
                <Grid item xl={4} md={4} xs={4}>
                  <Typography
                    sx={{
                      fontSize: '2.3rem',
                      lineHeight: 1,
                      fontWeight: 700,
                      color: '#2d2f32',
                    }}
                  >
                    31
                  </Typography>
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: '18px',
                      fontWeight: 400,
                      lineHeight: 1,
                      color: '#8a9196',
                      textTransform: 'uppercase'
                    }}
                  >
                    {translate.Yil}
                  </Typography>
                </Grid>
                <Grid item xl={4} md={4} xs={4}>
                  <Typography
                    sx={{
                      fontSize: '2.3rem',
                      lineHeight: 1,
                      fontWeight: 700,
                      color: '#2d2f32',
                    }}>
                    87
                  </Typography>
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: '18px',
                      fontWeight: 400,
                      lineHeight: 1,
                      color: '#8a9196',
                    }}>
                    {translate.AylikTalep}
                  </Typography>
                </Grid>
                <Grid item xl={4} md={4} xs={4}>
                  <Typography
                    sx={{
                      fontSize: '2.3rem',
                      lineHeight: 1,
                      fontWeight: 700,
                      color: '#2d2f32',
                    }}>
                    3457
                  </Typography>
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: '18px',
                      fontWeight: 400,
                      lineHeight: 1,
                      color: '#8a9196',
                    }}>
                    {translate.ToplamUretim}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid mt={10} item xl={12} md={12} xs={12}>
          <Box>
            <ProjectProcess />
          </Box>
        </Grid>

        <Grid mt={10} item xl={12} md={12} xs={12}>
          <Box>
            <ItemSlider />
          </Box>
        </Grid>

        <Grid mt={5} item xl={12} md={12} xs={12}>
          <Footer />
        </Grid>
      </Grid >
    </>
  );
}

export default App;
