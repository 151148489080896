import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import image1 from '../Assets/Hero Main Images/1.png';
import image2 from '../Assets/Hero Main Images/2.png';
import image3 from '../Assets/Hero Main Images/3.png';
import image4 from '../Assets/Hero Main Images/4.png';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, IconButton } from '@mui/material';

function HeroAreaImageSlider() {
    const [currentImage, setCurrentImage] = useState(image1);
    const navigate = useNavigate();

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleClickNextImage();
        }, 10000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [currentImage]);

    const handleClickNextImage = () => {
        if (currentImage === image1) {
            setCurrentImage(image2);
        } else if (currentImage === image2) {
            setCurrentImage(image3);
        } else if (currentImage === image3) {
            setCurrentImage(image4);
        } else if (currentImage === image4) {
            setCurrentImage(image1);
        }
    };

    const handleClickPreviousImage = () => {
        if (currentImage === image1) {
            setCurrentImage(image4);
        } else if (currentImage === image4) {
            setCurrentImage(image3);
        } else if (currentImage === image3) {
            setCurrentImage(image2);
        } else if (currentImage === image2) {
            setCurrentImage(image1);
        }
    };

    const handleImageClick = () => {
        navigate('/Iletisim');
    };

    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <img
                    style={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'cover',
                        cursor: 'pointer'
                    }}
                    src={currentImage}
                    alt='HeroImageArea'
                    onClick={handleImageClick}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '1%',
                        transform: 'translateY(-50%)',
                    }}
                >
                    <IconButton
                        onClick={handleClickPreviousImage}
                        sx={{
                            background: 'rgba(0,0,0,0.5)',
                            '&:hover': {
                                background: 'black',
                            },
                        }}
                    >
                        <KeyboardArrowLeftIcon
                            sx={{ color: 'white' }}
                        />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        right: '1%',
                        transform: 'translateY(-50%)',
                    }}
                >
                    <IconButton
                        onClick={handleClickNextImage}
                        sx={{
                            background: 'rgba(0,0,0,0.5)',
                            '&:hover': {
                                background: 'black',
                            },
                        }}
                    >
                        <KeyboardArrowRightIcon
                            sx={{ color: 'white' }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </>
    );
}

export default HeroAreaImageSlider;
