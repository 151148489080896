import React from 'react'
import ResponsiveAppBar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import { Box, Button, Grid, Typography } from '@mui/material'
import BreadCrumb from '../../Components/BreadCrumb'
import { useLocation, useNavigate } from 'react-router-dom'
import Layout from '../../Components/Layout'
import BannerLake from '../../Assets/Images/Lake/bannerlake.jpeg'
import LakeSmall from '../../Assets/Images/Lake/lakeSmallImage.jpeg'
import { useSelector } from 'react-redux'

function ItemDetail() {
  const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))
  const link = useLocation();
  console.log(link.pathname.split('/')[2]);


  const navigate = useNavigate();
  const handleItemButtonClick = () => {
    navigate('/Iletisim');
  }

  return (
    <>
      <ResponsiveAppBar />
      <BreadCrumb item={link.pathname.split('/')[2]} itemLink={link.pathname.split('/')[2]} categoryLink='Mutfak' category='Mutfak' name={link.pathname.split('/')[2]} />
      <Layout>
        <Grid mb={10} align='center' container spacing={0}>
          <Grid sx={{ display: 'block' }} align='center' item xl={12} md={12} xs={12} >
            <img style={{ width: '100%', height: '700px' }} src={BannerLake} alt='Trend' />
          </Grid>

          <Grid sx={{ height: '800px' }} item xl={6} md={6} xs={12}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end' }} >
              <img style={{ height: '70%' }} src={LakeSmall} alt='GranitImage' />
            </Box>
          </Grid>

          <Grid sx={{ height: '800px' }} pl={10} item xl={6} md={6} xs={12}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontFamily: "Poppins , 'sans-serif'",
                    color: '#bf8c4c',
                  }}
                >
                  Madison
                </Typography>
                <Typography sx={{
                  fontWeight: '400',
                  color: '#828282',
                  lineHeight: '30px',
                  wordBreak: 'break-all',
                  fontFamily: "Poppins , 'sans-serif'",
                  width: '370px',
                  textAlign: 'left'
                }}>
                    {translate.DawsonAciklama}
                </Typography>
                <Button
                  onClick={handleItemButtonClick}
                  sx={{
                    mt: 2,
                    textTransform: 'none',
                    boxShadow: 'none',
                    background: 'black',
                    ':hover': {
                      boxShadow: '10px',
                      background: 'black',
                    }

                  }} variant='contained'>
                  <Typography sx={{
                    fontWeight: '600',
                    fontSize: '18px',
                    lineHeight: '30px',
                    wordBreak: 'break-word',
                    fontFamily: "Poppins , 'sans-serif'",
                  }}>
                     {translate.Kesfedin}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid mb={5} style={{ display: 'flex', height: '100%' }} item xl={12} md={12} xs={12}>
            <Grid style={{ display: 'flex', height: '100%' }} container spacing={0}>
              <Grid align='left' item xl={6} md={6} xs={12}>
                <Box style={{ height: '100%', width: '100%' }}>
                  <img style={{ height: '100%', width: '100%' }} src={require('../../Assets/Images/Lake/LakeImageLeft2.jpeg')} alt='Image4' />
                </Box>
              </Grid>

              <Grid style={{ display: 'flex' }} padding={0} margin={0} item xl={6} md={6} xs={12}>
                <Grid container spacing={0}>
                  <Grid item xl={6} md={6} xs={12}>
                    <Box style={{ height: '100%', width: '100%' }}>
                      <img style={{ height: '100%', width: '100%' }} src={require('../../Assets/Images/Lake/LakeImage1.jpeg')} alt='Image5' />
                    </Box>
                  </Grid>
                  <Grid item xl={6} md={6} xs={12}>
                    <Box style={{ background: '#f9f6f3', height: '100%', width: '100%' }}>
                      <Box pt={4} style={{ height: '100%', width: '70%', }}>
                        <Typography
                          sx={{
                            fontWeight: '600',
                            color: '#232323',
                            fontSize: '16px',
                            lineHeight: '22px',
                            fontFamily: "Poppins , 'sans-serif'",
                            textAlign: 'start'
                          }}
                        >
                           {translate.DawsonBaslik1}
                        </Typography>

                        <Typography
                          mt={2}
                          sx={{
                            fontWeight: '400',
                            color: '#828282',
                            fontSize: '18px',
                            lineHeight: '30px',
                            textAlign: 'start'
                          }}
                        >
                            {translate.DawsonYazi1}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid align='center' item xl={6} md={6} xs={12}>
                    <Box style={{ background: '#f9f6f3', height: '100%', width: '100%' }}>
                      <Box pt={4} style={{ height: '100%', width: '70%', }}>
                        <Typography
                          sx={{
                            fontWeight: '600',
                            color: '#232323',
                            fontSize: '16px',
                            lineHeight: '22px',
                            fontFamily: "Poppins , 'sans-serif'",
                            textAlign: 'start'
                          }}
                        >
                            {translate.DawsonBaslik2}
                        </Typography>
                        <Typography
                          mt={2}
                          sx={{
                            fontWeight: '400',
                            color: '#828282',
                            fontSize: '18px',
                            lineHeight: '30px',
                            textAlign: 'start'
                          }}
                        >
                           {translate.DawsonYazi2}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xl={6} md={6} xs={12}>
                    <Box style={{ height: '100%', width: '100%' }}>
                      <img style={{ height: '100%', width: '100%' }} src={require('../../Assets/Images/Lake/LakeImageLeft.jpeg')} alt='Image6' />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


       
          <Grid item xl={12} md={12} xs={12}>
                        <Typography sx={{
                            fontWeight: '600',
                            fontSize: '2.85rem',
                            lineHeight: '3.4rem',
                            wordBreak: 'break-word',
                            fontFamily: "Poppins , 'sans-serif'",
                        }}>
                            {translate.MutfaktaSiklik}
                        </Typography>

                        <Typography mt={4} sx={{
                            color: '#828282',
                            fontWeight: '400',
                            fontSize: '18px',
                            lineHeight: '30px',
                            wordBreak: 'break-word',
                            fontFamily: "Poppins , 'sans-serif'",

                        }}>
                            {translate.MutfaktaSiklikIcerik1}
                        </Typography>


                        <Typography mt={3} sx={{
                            color: '#828282',
                            fontWeight: '400',
                            fontSize: '18px',
                            lineHeight: '30px',
                            wordBreak: 'break-word',
                            fontFamily: "Poppins , 'sans-serif'",

                        }}>
                            {translate.MutfaktaSiklikIcerik2}
                        </Typography>
                    </Grid>
        </Grid>
      </Layout>
      <Footer />
    </>
  )
}

export default ItemDetail