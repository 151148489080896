import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';


function ItemDescCard(props) {
    return (
        <Link underline='none' href={`/Mutfaklar/${props.name}`}>
            <Card sx={{
                position: 'relative',
                top: 0,
                transition: 'top ease 0.5s',
                maxWidth: { md: 510, xs: '95%' },
                marginTop: 2,
                cursor: 'pointer',
                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
                '&:hover': {
                    top: '-10px',
                    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;',
                },
                '&:hover .CardMediaImg': {
                    filter: 'brightness(0.5)',
                }
            }}>
                <CardMedia
                    className='CardMediaImg'
                    sx={{ height: 500 }}
                    image={props.image}
                />
                <CardContent sx={{ textAlign: 'center' }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {props.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.description}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    )
}

export default ItemDescCard