import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ConnectUs from './Pages/ConnectUs';
import OurServices from './Pages/OurServices';
import Kitchen from './Pages/Kitchen';
import Opportunities from './Pages/Opportunities';
import ItemDawson from './Pages/Items/ItemDawson';
import ItemAldana from './Pages/Items/ItemAldana';
import ItemMadison from './Pages/Items/ItemMadison';
import ItemTrend from './Pages/Items/ItemTrend';
import { Provider } from 'react-redux'
import { store } from './Redux/store'
import Projects from './Pages/Projects';
import BlogForm from './Pages/BlogForm';
import Blog from './Pages/Blog';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/BlogForm",
    element: <BlogForm />,
  },
  {
    path: "/Blog",
    element: <Blog />,
  },
  {
    path: '/Hizmetlerimiz',
    element: <OurServices />
  },
  {
    path: '/Iletisim',
    element: <ConnectUs />
  },
  {
    path: '/Firsatlar',
    element: <Opportunities />
  },
  {
    path: '/Mutfak',
    element: <Kitchen />
  },
  {
    path: '/Projelerimiz',
    element: <Projects />
  },
  {
    path: '/Mutfaklar/Dawson',
    element: <ItemDawson />
  },
  {
    path: '/Mutfaklar/Aldana',
    element: <ItemAldana />
  },
  {
    path: '/Mutfaklar/Madison',
    element: <ItemMadison />
  },
  {
    path: '/Mutfaklar/Trend',
    element: <ItemTrend />
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
