import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import CallMadeIcon from '@mui/icons-material/CallMade';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useSelector } from 'react-redux';

function ItemSlider() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))
    const styles = {
        ImgWrapperBox: {
            margin: '5px 20px',
            position: 'relative',
            cursor: 'pointer',
            '&:hover .test123': {
                opacity: 0.9,
            }
        },
        TextWrapperBox: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: '100%',
            width: '100%',
            opacity: 0,
            transition: '.9s ease',
            backgroundColor: 'white'
        },
        TextItem: {
            "color": "black",
            "fontSize": "20px",
            "position": "absolute",
            "top": "50%",
            "left": "50%",
            "WebkitTransform": "translate(-50%, -50%)",
            "MsTransform": "translate(-50%, -50%)",
            "transform": "translate(-50%, -50%)",
            "textAlign": "center"
        },
        LeftArrow: {
            color: 'white'
        },
        IconButtonLeft: {
            background: 'rgba(0,0,0,0.5)',
            '&:hover': {
                background: 'black',
            }
        },
        LeftArrowWrapper: {
            position: 'absolute',
            top: '50%',
            left: '2%',
            zIndex: 2
        },
        RightArrow: {
            color: 'white'
        },
        IconButtonRight: {
            background: 'rgba(0,0,0,0.5)',
            '&:hover': {
                background: 'black',
            }
        },
        RightArrowWrapper: {
            position: 'absolute',
            top: '50%',
            right: '2%',
            zIndex: 2
        }

    }
    return (
        <Box sx={{ position: 'relative' }}>
            <Box sx={{ display: 'block', pr: 3, pl: 3 }}>
                <Box sx={{ float: 'left' }}>
                    <Typography
                        sx={{
                            fontWeight: '500',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontSize: { xs: '1.45rem', md: '2.3rem' },
                            mb: { xs: 2 }
                        }}
                    >
                        {translate.StiliniziYansitanTasarimlar}
                    </Typography>
                </Box>


                <Box sx={{ float: 'right' }}>
                    <a style={{
                        float: 'right',
                        textDecoration: 'none'
                    }}
                        href='/Mutfak'>
                        <Typography
                            sx={{
                                fontWeight: '500',
                                fontFamily: "Poppins , 'sans-serif'",
                                color: 'black',
                                fontSize: { xs: '0.8rem', md: '1.8rem' },
                            }}
                        >
                            {translate.TumMutfakUrunleri}
                        </Typography>
                    </a>
                </Box>
            </Box>
            <Box sx={styles.LeftArrowWrapper}>
                <IconButton sx={styles.IconButtonLeft}>
                    <KeyboardArrowLeftIcon sx={styles.LeftArrow} />
                </IconButton>
            </Box>
            <Box sx={styles.RightArrowWrapper}>
                <IconButton sx={styles.IconButtonRight}>
                    <KeyboardArrowRightIcon sx={styles.RightArrow} />
                </IconButton>
            </Box>

            <Box sx={{ position: 'relative', width: '100%', display: 'inline-flex', overflow: 'scroll', }}>

                <a href='/Mutfaklar/Dawson'>
                    <Box sx={styles.ImgWrapperBox}>
                        <Box >
                            <img style={{ height: '596px', width: '476px', display: 'block' }}
                                src={require('../Assets/Images/Akrilik/12.jpeg')} alt='Dawson' />
                        </Box>
                        <Box className='test123' sx={styles.TextWrapperBox}>
                            <CallMadeIcon sx={{ float: 'right', marginTop: 2, marginRight: 2 }} />
                            <Typography sx={styles.TextItem}>
                                Dawson
                            </Typography>
                        </Box>
                    </Box>
                </a>

                <a href='/Mutfaklar/Aldana'>
                    <Box sx={styles.ImgWrapperBox}>
                        <Box >
                            <img style={{ height: '596px', width: '476px', display: 'block' }}
                                src={require('../Assets/Images/Mat Akrilik/6.jpeg')} alt='Aldana' />
                        </Box>
                        <Box className='test123' sx={styles.TextWrapperBox}>
                            <CallMadeIcon sx={{ float: 'right', marginTop: 2, marginRight: 2 }} />
                            <Typography sx={styles.TextItem}>
                                Aldana
                            </Typography>
                        </Box>
                    </Box>
                </a>


                <a href='/Mutfaklar/Madison'>
                    <Box sx={styles.ImgWrapperBox}>
                        <Box >
                            <img style={{ height: '596px', width: '476px', display: 'block' }}
                                src={require('../Assets/Images/Lake/12.jpeg')} alt='Madison' />
                        </Box>
                        <Box className='test123' sx={styles.TextWrapperBox}>
                            <CallMadeIcon sx={{ float: 'right', marginTop: 2, marginRight: 2 }} />
                            <Typography sx={styles.TextItem}>
                                Madison
                            </Typography>
                        </Box>
                    </Box>
                </a>

                <a href='/Mutfaklar/Trend'>
                    <Box sx={styles.ImgWrapperBox}>
                        <Box >
                            <img style={{ height: '596px', width: '476px', display: 'block' }}
                                src={require('../Assets/Images/Balon/5.jpeg')} alt='Trend' />
                        </Box>
                        <Box className='test123' sx={styles.TextWrapperBox}>
                            <CallMadeIcon sx={{ float: 'right', marginTop: 2, marginRight: 2 }} />
                            <Typography sx={styles.TextItem}>
                                Trend
                            </Typography>
                        </Box>
                    </Box>
                </a>
            </Box>
        </Box>

    )
}

export default ItemSlider