import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Logo2 from '../../src/Assets/LOGO/TVMobilyaBlack.png'
import { Button, Link } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import Flag from 'react-world-flags';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../Redux/Slices/UserLanguageSlice';


function ResponsiveAppBar({ isHomePage }) {
    const languageCode = useSelector((state) => state.Language.userLanguageFromLocalStorage);
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))
    const dispatch = useDispatch();

    const [anchorElNav, setAnchorElNav] = React.useState(null);


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    const [navbarStyle, setNavbarStyle] = React.useState({
        background: 'transparent',
        color: 'black',
        boxShadow: 'none',
    });


    useEffect(() => {
        if (!isHomePage) {
            SetisScrolled(true);
        }
    }, []);


    const changeBackground = () => {
        if (isHomePage) {
            changeNavLogoColor();
        }
        if (window.scrollY > 10) {
            setNavbarStyle({
                background: 'transparent',
                color: 'black',
                backdropFilter: 'blur(40px)'
            })
        }
        else {
            setNavbarStyle({
                background: 'transparent',
                color: 'black',
                boxShadow: 'none',
            })
        }
    }

    const [isScrolled, SetisScrolled] = useState(false);
    const changeNavLogoColor = () => {
        if (window.scrollY > 800) {
            SetisScrolled(true);
        }
        else {
            SetisScrolled(false);
        }
    }

    window.addEventListener('scroll', changeBackground);

    return (
        <AppBar
            sx={navbarStyle}
            position="fixed">
            <Container maxWidth="xxl">
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    disableGutters>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            mr: 2
                        }}
                    >
                        <img
                            style={{
                                height: '64px'
                            }}
                            src={Logo2} alt='TanrıverdiMobilya ' />
                    </Box>
                    <Typography
                        variant="h7"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: "'Montserrat', sans-serif",
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Tanrıverdi Mutfak
                    </Typography>
                    <Box
                        sx={{ display: { xs: 'flex', md: 'none' }, flex: 'auto', justifyContent: 'space-between' }}
                    >

                        <Box>
                            <IconButton
                                mt={3}
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon sx={{ color: 'black' }} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <MenuItem key='mutfak' onClick={handleCloseNavMenu}>
                                    <Link underline='none' href='/Mutfak'>
                                        <Typography
                                            sx={{
                                                color: '#2b2b2b',
                                                fontFamily: "'Montserrat', sans-serif",
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                            textAlign="center">{translate.Mutfak}</Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem key='kategoriler' onClick={handleCloseNavMenu}>
                                    <Link underline='none' href='/Hizmetlerimiz'>
                                        <Typography
                                            sx={{
                                                color: '#2b2b2b',
                                                fontFamily: "'Montserrat', sans-serif",
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                            textAlign="center">{translate.Hizmetlerimiz}</Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem key='kategoriler' onClick={handleCloseNavMenu}>
                                    <Link underline='none' href='/Projelerimiz'>
                                        <Typography
                                            sx={{
                                                color: '#2b2b2b',
                                                fontFamily: "'Montserrat', sans-serif",
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                            textAlign="center">{translate.Projelerimiz}</Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem key='hakkimizda' onClick={handleCloseNavMenu}>
                                    <Link underline='none' href='/Firsatlar'>
                                        <Typography
                                            sx={{
                                                color: '#2b2b2b',
                                                fontFamily: "'Montserrat', sans-serif",
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                            textAlign="center">{translate.Firsatlar}</Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem key='blog' onClick={handleCloseNavMenu}>
                                    <Link underline='none' href='/Blog'>
                                        <Typography
                                            sx={{
                                                color: '#2b2b2b',
                                                fontFamily: "'Montserrat', sans-serif",
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                            textAlign="center">{translate.Blog}</Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem key='iletisim' onClick={handleCloseNavMenu}>
                                    <Link underline='none' href='/Iletisim'>
                                        <Typography
                                            sx={{
                                                color: '#2b2b2b',
                                                fontFamily: "'Montserrat', sans-serif",
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                            textAlign="center">{translate.Iletisim}</Typography>
                                    </Link>
                                </MenuItem>

                            </Menu>
                        </Box>


                        <Box
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                                mr: 2,
                                alignContent: 'center',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}
                        >
                            <img
                                style={{
                                    height: '64px'
                                }}
                                src={Logo2} alt='TanrıverdiMobilya ' />
                        </Box>



                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <Box sx={{ display: 'contents', paddingRight: 10 }}>
                                <Tooltip title={translate.Dil}>
                                    <Button onClick={() => dispatch(changeLanguage())}>
                                        <Flag height={22} code={languageCode === 'tr' ? 'tr' : 'gb'} />
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display='flex'
                        justifyContent='space-between'
                        p='0 10%'
                        sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <Link
                            underline='none'
                            key='Mutfak'
                            href='/Mutfak'
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            <Typography
                                variant="h7"
                                noWrap
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontWeight: 700,
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {translate.Mutfak}
                            </Typography>
                        </Link>
                        <Link
                            underline='none'
                            key='kategoriler'
                            href='/Hizmetlerimiz'
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            <Typography
                                variant="h7"
                                noWrap
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontWeight: 700,
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {translate.Hizmetlerimiz}
                            </Typography>
                        </Link>
                        <Link
                            underline='none'
                            key='kategoriler'
                            href='/Projelerimiz'
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            <Typography
                                variant="h7"
                                noWrap
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontWeight: 700,
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {translate.Projelerimiz}
                            </Typography>
                        </Link>
                        <Link
                            underline='none'
                            key='firsatlar'
                            href='/Firsatlar'
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            <Typography
                                variant="h7"
                                noWrap
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontWeight: 700,
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {translate.Firsatlar}
                            </Typography>
                        </Link>
                        <Link
                            underline='none'
                            key='blog'
                            href='/Blog'
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            <Typography
                                variant="h7"
                                noWrap
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontWeight: 700,
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {translate.Blog}
                            </Typography>
                        </Link>
                        <Link
                            underline='none'
                            key='iletisim'
                            href='/Iletisim'
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            <Typography
                                variant="h7"
                                noWrap
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontWeight: 700,
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {translate.Iletisim}
                            </Typography>
                        </Link>

                    </Box>

                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Box sx={{ display: 'contents', paddingRight: 10 }}>
                            <Tooltip title={translate.Dil}>
                                <Button onClick={() => dispatch(changeLanguage())}>
                                    <Flag height={22} code={languageCode === 'tr' ? 'tr' : 'gb'} />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;