export const loadState = (key) => {
    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState === null) {
            return false;
        }
        return serializedState;
    } catch (err) {
        return undefined;
    }
}


export const saveState = (key, value) => {
    try {
        localStorage.setItem(key, value)
    } catch (err) {
        return false;
    }
}