import { Grid, Link, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))
    return (
        <>
            <Grid pt={10}
                align='center'
                container spacing={0}
                sx={{ background: '#2b2b2b', color: 'white' }} >
                <Grid pl={4} pr={4} mb={5} item xl={4} md={4} xs={12}>
                    <Typography
                        display='inline'
                        sx={{
                            fontFamily: "'Montserrat', sans-serif",
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        TANRIVERDI MUTFAK
                    </Typography>
                    <Box mt={3} sx={{ maxWidth: '400px' }}>
                        <Typography
                            sx={{
                                textAlign: 'justify'
                            }}
                        >
                            5 yıl önce başladığımız bu yolculukta, binlerce müşterimizin hayatına dokunduk. Yaşamdan zevk alanlar için ürettik ve üretmeye, hayatınıza dokunmaya devam ediyoruz.
                        </Typography>
                    </Box>
                </Grid>
                <Grid pl={4} pr={4} pb={5} item xl={4} md={4} xs={12}>
                    <Typography
                        sx={{
                            fontFamily: "'Montserrat', sans-serif",
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        {translate.SosyalMedyadaBiz}
                    </Typography>
                    <Box mt={3}>
                        <InstagramIcon onClick={() => navigate('')} sx={{ fontSize: '2.2em', margin: '3px 5px' }} />
                        <FacebookIcon sx={{ fontSize: '2.2em', margin: '3px 5px' }} />
                    </Box>
                </Grid>

                <Grid pl={4} pr={4} mb={5} align='left' item xl={4} md={4} xs={9}>
                    <Typography
                        sx={{
                            fontFamily: "'Montserrat', sans-serif",
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        {translate.BizeUlasin}
                    </Typography>
                    <Box mt={3}>
                        <LocationOnIcon sx={{ fontSize: '1.5em', margin: '0 5px', }} />
                        <a style={{ color: 'white', textDecoration: 'none' }} href='/Iletisim'>
                            <Typography display='inline'>
                                {translate.Adres}
                            </Typography>
                        </a>
                    </Box>
                    <Box mt={2}>
                        <Link
                            underline='none'
                            sx={{
                                display: 'inlie-flex',
                                color: 'white'
                            }}
                            href='tel:905416934209'>
                            <LocalPhoneIcon sx={{ display: 'inlie-flex', fontSize: '1.5em', margin: '0 5px', }} />
                            <Typography textAlign="center">  +(90) 541 693 42 09</Typography>
                        </Link>
                    </Box>
                    <Box mt={2}>
                        <Link
                            underline='none'
                            sx={{
                                display: 'inlie-flex',
                                color: 'white'
                            }}
                            href='https://wa.me/905416934209'>
                            <WhatsAppIcon sx={{ display: 'inlie-flex', fontSize: '1.5em', margin: '0 5px', }} />
                            <Typography textAlign="center">  +(90) 541 693 42 09</Typography>
                        </Link>
                    </Box>
                </Grid>

                <Grid sx={{ background: '#2b2b2b', color: 'white' }} container spacing={0}>
                    <Grid width='100%' pt={2} sx={{ background: 'rgba(0, 0, 0, 0.2)' }} item xl={12} md={12} xs={12}>
                        <Typography sx={{ color: 'white', textAlign: 'center' }}>
                            Copyrights © 2022 - 2023 {translate.TumHaklariSaklidir}. Tanrıverdi Mutfak Dekorasyon Mobilya San. ve Tic. A.Ş.
                        </Typography>
                        <Link href='http://www.camurcuk.com' underline='none'>
                            <Typography sx={{ color: 'white', textAlign: 'center', fontSize: '10px' }}>
                                Camurcuk Design/Engineering
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>


        </>
    )
}

export default Footer