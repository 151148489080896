import React from 'react'

function Layout({ children }) {
    return (
        <div style={{
            width: '100%',
            position:'relative',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
            <div style={{
                maxWidth: '1920px',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center'
            }}>
                {children}
            </div>
        </div>
    )
}

export default Layout