import React from 'react';
import { Grid, Typography, Card, CardContent, CardMedia } from '@mui/material';
import { useSelector } from 'react-redux';

function ProjectProcess() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))
    return (
        <Grid container justifyContent="center" sx={{ background: '#f7f7f7' }} spacing={3}>
            <Grid item xs={12}>
                <Typography sx={{
                    fontSize: '32.2px',
                    fontWeight: '600',
                    fontFamily: "Poppins , 'sans-serif'",
                    fontStyle: 'normal',
                    lineHeight: '130%',
                    textAlign: 'center',
                    mb: 2
                }}>
                    {translate.ProjeSureci}
                </Typography>
                <Typography sx={{
                    m: '1em 10% 1em 10%',
                    fontSize: '18px',
                    color: 'rgb(130,130,130)',
                    fontWeight: '400',
                    fontFamily: "Poppins , 'sans-serif'",
                    fontStyle: 'normal',
                    lineHeight: '130%',
                    textAlign: 'center'
                }}>
                    {translate.Projelerinizintanismavebaslangicindan}
                </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center' }}>
                    <CardMedia component="img" sx={{ width: 200 }} image="https://zortasmutfak.com/wp-content/uploads/2019/03/desige_cons.jpg" alt="İlk Tanışma" />
                    <CardContent>
                        <Typography sx={{
                            mb: 1,
                            fontSize: '19px',
                            fontWeight: '600',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '130%',
                            textAlign: 'center'
                        }}>{translate.ilktanisma}</Typography>
                        <Typography sx={{
                            fontSize: '14px',
                            color: 'rgb(130,130,130)',
                            fontWeight: '300',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '120%',
                            textAlign: 'center'
                        }}>
                            {translate.ProjeAşamasinagecmeden}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={3}>
                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center' }}>
                    <CardMedia component="img" sx={{ width: 200 }} image="https://zortasmutfak.com/wp-content/uploads/2019/03/pick_style.jpg" alt="Detaylı Ölçü" />
                    <CardContent>
                        <Typography sx={{
                            mb: 1,
                            fontSize: '19px',
                            fontWeight: '600',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '130%',
                            textAlign: 'center'
                        }}>{translate.Detayliolcu}</Typography>
                        <Typography sx={{
                            fontSize: '14px',
                            color: 'rgb(130,130,130)',
                            fontWeight: '300',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '120%',
                            textAlign: 'center'
                        }}>
                            {translate.Ekibimizprojelendirmeyegecmedenonce}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={3}>
                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center' }}>
                    <CardMedia component="img" sx={{ width: 200 }} image="https://zortasmutfak.com/wp-content/uploads/2019/03/kitchen_install.jpg" alt="Tasarım" />
                    <CardContent>
                        <Typography sx={{
                            mb: 1,
                            fontSize: '19px',
                            fontWeight: '600',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '130%',
                            textAlign: 'center'
                        }}>{translate.Tasarim}</Typography>
                        <Typography sx={{
                            fontSize: '14px',
                            color: 'rgb(130,130,130)',
                            fontWeight: '300',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '120%',
                            textAlign: 'center'
                        }}>
                            {translate.TanriverdiMutfakDesignFarkiveProfesyonelMimarlari}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={3}>
                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center' }}>
                    <CardMedia component="img" sx={{ width: 200 }} image="https://zortasmutfak.com/wp-content/uploads/2019/03/kitchen_clen.jpg" alt="Teslimat" />
                    <CardContent>
                        <Typography sx={{
                            mb: 1,
                            fontSize: '19px',
                            fontWeight: '600',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '130%',
                            textAlign: 'center'
                        }}>{translate.Teslimat}</Typography>
                        <Typography sx={{
                            fontSize: '14px',
                            color: 'rgb(130,130,130)',
                            fontWeight: '300',
                            fontFamily: "Poppins , 'sans-serif'",
                            fontStyle: 'normal',
                            lineHeight: '120%',
                            textAlign: 'center'
                        }}>
                            {translate.MüsterimemnuniyetiodakliHizliveKaliteli}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default ProjectProcess;
