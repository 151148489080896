import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from '@mui/material'
import { useSelector } from 'react-redux'
function BreadCrumb({category , item , name , categoryLink , itemLink , nameLink}) {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))
    const styles = {
        BreadCrumbLinks: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 700,
            marginBottom: '1em',
            color: '#828282',
            fontSize: 'clamp(0.9em, 0.8vw, 1.5em)'
        }
    }

    return (
        <>
            <Grid mt={10} container spacing={0}>
                <Grid item xl={12} md={12} xs={12}>
                    <Box sx={{
                        background: '#f5f5f5',
                        padding: '1vw 6vw',
                        display: {
                            xs:'flex',
                            md:'block'
                        },
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <Typography sx={{
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 700,
                            marginBottom: '1em'
                        }}>
                            {name}
                        </Typography>

                        <Typography
                            sx={styles.BreadCrumbLinks}
                        >
                            <Link sx={styles.BreadCrumbLinks} underline='none' href='/'> {translate.Anasayfa} </Link>
                            
                            <Link sx={styles.BreadCrumbLinks} underline='none' href={categoryLink}> {category ?  '/ ' + category  : ''} </Link>
                            
                            <Link sx={styles.BreadCrumbLinks} underline='none' href={itemLink}> {item ?  '/ ' + item  : ''} </Link>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default BreadCrumb